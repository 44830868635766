body {
  margin: 0;
  font-family: "PeydaWeb";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FF0000;
}

.drop_down {
  position: absolute;
  top: 130%;
  width: 180px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  flex-wrap: wrap;
  background-color: #000;
  border-radius: 10px;
  overflow: hidden;
  padding: 18px;
  gap: 18px;
  border: 1px solid #fff;
  z-index: 99999;
}

.pointer {
  cursor: pointer;
}
@font-face {
  font-family: "PeydaWeb";
  src: url(../public/PeydaWeb.woff2);
}
@font-face {
  font-family: "Yekan";
  src: url(../public/iranyekanweblight.woff);
}

.welcome_saturn_1{
  position: absolute;
  left: 0;
  width: 35%;
  bottom: -5%;
  opacity: 0.8;
}

.welcome_saturn_2{
  position: absolute;
  width: 35%;
  right: 5%;
  top: 15%;
  opacity: 0.8;
  transform: rotateY(180deg);
}

button {
  border: none;
}

.z-10{
  z-index: 10;
  position: relative;
}

.title-primary{
  color: #27a776;
  font-size: 40px;
  font-weight: 800;
}


.example_card img {
  box-shadow:  0 0 20px 0px #27a776d7;
  width: 92%;
  border: 1px solid #27a776;
  margin: 15px;
  transition: 300ms all;
}

.example_card img:hover {
  opacity: 0.6;
}
.text-brand {
  color: #27a776 !important;
}
.img_max{
  box-shadow:  0 0 20px 0px #27a776d7;
  border: 1px solid #27a776;
}

@media screen and (max-width : 992px) {
  .welcome_saturn_2{
    width: 250px;
  }
  .welcome_saturn_1{
    width: 250px;
  }
  .title-primary{
    font-size: 30px;
   }
   .footer {
    position: relative !important;
  }
  .hero p{
    font-size: 14px !important;
  }
}

@media screen and (min-width : 992px) {
  section{
    height: 100vh;
  }
  
}

